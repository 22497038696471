import i18n from '@/i18n'
import { mapState } from 'vuex'
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import Layout from '@/apps/core/components/Layout'
import sections from '@/apps/dido/views/defaultNavigationDrawerSections'
import { formatDate } from '@/apps/core/helpers/utils'

export default {
  name: 'BudgetList',
  components: {
    Layout,
    CrudTable
  },
  data () {
    return {
      sections: sections,
      customOptions: { 'sortBy': ['id'], 'sortDesc': [true] },
      storeModule: 'budgets',
      title: i18n.tc('delegations.sectionName')
    }
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig },
      loading: function (state) { return state[this.storeModule].loading }
    }),
    /**
   * Returns object used to generate the router-link slot for each item
   * @returns {Object}
   */
    linkAttributes () {
      const linkAttributes = {}
      let value = this.crudTableConfig.headers?.length > 0 ? this.crudTableConfig.headers[0].value : ''

      linkAttributes['path'] = `item.${value}`
      linkAttributes['name'] = value
      return linkAttributes
    }
  },
  methods: {
    goToNewBudget () {
      try {
        this.$router.push({ name: 'BudgetDetail', params: { id: 'new' } })
      } catch (error) {
        console.error(error)
      }
    },

    /**
   * Returns the formatted date from the "period" dictionary to render into the period slot.
   * @param itemPeriod
   * @returns {string}
   */
    getParsedDate (itemPeriod) {
      return `${formatDate(JSON.parse(itemPeriod).lower)} - ${formatDate(JSON.parse(itemPeriod).upper)}`
    }
  }
}
